/* Override styles for react-date-picker */
.react-date-picker {
  width: 100%;
}

.react-date-picker__wrapper {
  border: 1px solid #e7ebed; /* Blue border for date range picker */
  height: 44px;
  border-radius: 8px;
  padding: 9px 16px;
}

/* Customize the calendar icon */
.react-date-picker__calendar-button {
  display: none;
}

.react-date-picker__inputGroup input {
  color: #1a2135;
  font-size: 14px;
  border: none;
}

/* Override styles for react-daterange-picker */
.react-daterange-picker {
  background-color: #fafafa; /* Light background for range picker */
  border-radius: 8px;
}

.react-daterange-picker__wrapper {
  border: 1px solid #e7ebed; /* Blue border for date range picker */
  height: 44px;
  border-radius: 8px;
  padding: 9px 16px;
}

.react-daterange-picker__inputGroup input {
  color: #1a2135;
  font-size: 14px;
  border: none;
}

.react-daterange-picker__calendar-button {
  display: none;
}
