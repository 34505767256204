.variable.valid { 
    padding: 2px 8px;
    border-radius: 4px;
    border: solid 1px #374667;
    background-color: #e6fdee;
    border-style: dotted;
}

.variable.invalid {
  padding: 2px 8px;
  border-radius: 4px;
  border: solid 1px #374667;
  background-color: #ff9d9d;
  border-style: dotted;
}
  