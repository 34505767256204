.react-daterange-picker,
.react-daterange-picker *,
.react-daterange-picker *:before,
.react-daterange-picker *:after {
  row-gap: 8px;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation {
  display: flex;
}
.react-calendar__navigation__prev-button {
  order: 0;
}
.react-calendar__navigation__prev-next {
  order: 2;
}

.react-calendar__navigation__label {
  font-size: 22px !important;
  color: #374667;
  order: -1;
}

.react-daterange-picker__inputGroup__day:focus,
.react-daterange-picker__inputGroup__month:hover,
.react-daterange-picker__inputGroup__year:focus {
  background: rgba(0, 0, 255, 0.1);
}

/* General calendar styles */
.react-calendar {
  background-color: #fff; /* White background for calendar */
  border: 1px solid #e7ebed; /* Light grey border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for a floating effect */
  padding: 24px;
}
.react-calendar__navigation button {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.2px;
  color: #1a2135 !important;
  background: #fff;
}

/* Header with month and year */
.react-calendar__navigation {
  background-color: #fff;
  color: #374667;
  border-bottom: 1px solid #ddd;
  border-radius: 8px 8px 0 0;
}

.react-calendar__navigation button {
  color: white;
  font-size: 18px;
}

/* Days of the week (short names like Mon, Tue) */
.react-calendar__month-view__weekdays {
  background-color: #f8f9fa;
  font-weight: bold;
  color: #495057;
}

.react-calendar__month-view__weekdays__weekday {
  background: #fff;
  color: #1a2135;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

/* Individual day tiles */
.react-calendar__tile {
  font-size: 14px;
  background: #fff;
  transition: background-color 0.3s ease;
}

.react-calendar__tile:hover,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus,
.react-calendar__tile--active {
  background-color: #374667;
  color: white;
}

.react-calendar__tile--now {
  background-color: rgb(184, 184, 184);
  color: white;
}

.react-calendar__navigation__arrow {
  color: white;
}

/* Calendar week numbers */
.react-calendar__tile--weekNumber {
  background-color: #f0f0f0;
  color: #333;
}

.react-calendar__tile--rangeStart {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #374667;
  color: white;
}

/* For the last day in a range */
.react-calendar__tile--rangeEnd {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #374667;
  color: white;
}

/* Style range days between the start and end */
.react-calendar__tile--range {
  background-color: #374667;
  color: #fff;
}
